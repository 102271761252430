import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./Auth";
import { useNavigate } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";
import { DeleteTask } from "./Delete";

function TaskComponent(props) {

    //const { children, value, index, ...other } = props;
    const { value } = props;

    const this_priority = value;

    const navigate = useNavigate();
    function refreshPage() {
	window.location.reload(true);
    };

    const { currentUser } = useContext(AuthContext);
    const [curTasks, setCurTasks] = useState([]);
    
    useEffect(() => {

	if (!currentUser) {
	    navigate("/");
	}

	try {
	const userId = currentUser.uid;
    
	onValue(ref(db, '/users/' + userId + '/priorities/' + this_priority + '/priority_items'), (snapshot) => {
	    snapshot.forEach(snap => {
		const priorityItem = snap.val();
		onValue(ref(db, '/users/' + userId + '/priorities/' + this_priority + '/priority_items/' + priorityItem.name + '/tasks'), (snapshot2) => {
		    snapshot2.forEach(snap2 => {
			setCurTasks(curTasks => [...curTasks, snap2.val()]);
		    });
		});
	    });

	}, {
	    onlyOnce: true
	});

	} catch(error) {
	    navigate("/");
	}

    
    }, [currentUser, navigate, this_priority]);

    const [show,setShow] = useState(false);
    const [clickedTask, setClickedTask] = useState({});


 

    return (
	<>
	  {curTasks.map((task,index) => 
		  <div className="item" key={index}>
			<button className="item-button" onClick={() => { setShow(!show); setClickedTask(task);}}>
	              {task.name}
	              <div className="label">{task.priority_item} | {task.due_date}</div>
	      	    </button>      
	          </div>
	  )}

	  { show ? <div className="item-tools">
		      <button className="btn" onClick={() => { setShow(!show); }}><i className="bi bi-x" style={{fontSize: "0.9rem"}}></i></button>
	              <button className="btn btn-success btn-sm" onClick={() => { setShow(!show); DeleteTask(currentUser.uid, clickedTask); refreshPage(); }}><i className="bi bi-check2-circle" style={{fontSize: "1.5rem"}}></i></button>
	            </div> : null }
	      
	</>
    );
};


export default TaskComponent;

    
