import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth";
import { db } from "../firebase";
import { get, ref, child, set, onValue } from "firebase/database";

const AddPage = () => {

    const { currentUser } = useContext(AuthContext);
    const [priorities, setPriorities] = useState([]);
    const [priorityItems, setPriorityItems] = useState([]);

    const navigate = useNavigate();
   
    useEffect(() => {
    	document.title = "home | (add)";

	if (!currentUser) {
	    navigate("/");
    	}
	try {

	    const userId = currentUser.uid;

	    onValue(ref(db, '/users/' + userId + '/priorities/'), (snapshot) => {
		snapshot.forEach(snap => {
		    let priority = snap.val();
		    setPriorities(priorities => [...priorities, priority]);
		    console.log(priority);
		    Object.entries(priority['priority_items']).map(([key, value]) => {
			setPriorityItems(priorityItems => [...priorityItems, value.name]);
		    });
		});
	    }, {
		onlyOnce: true
	    });
	} catch(error) {
	    alert(error);
	}

    }, [currentUser, navigate]);

    const handleSubmitTask = (e) => {
	e.preventDefault();
	const { name, priority_item, priority, due_date } = e.target.elements;
	try {
	    get(child(ref(db), `/users/${currentUser.uid}/priorities/${priority.value}`)).then((snapshot) => {

		if (!snapshot.exists()) {
		    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value), {
			name: priority.value,
			color: 'white',
			order: 99
		    });
		}

	    }).catch((error) => {
		console.error(error);
	    });



	    get(child(ref(db), `/users/${currentUser.uid}/priorities/${priority.value}/priority_items/${priority_item.value}`)).then((snapshot) => {
		if (snapshot.exists()) {
		    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value + '/priority_items/' + priority_item.value + '/tasks/' + name.value), {
			name: name.value,
			priority_item: priority_item.value,
			priority: priority.value,
			due_date: due_date.value.toString()
		    });

		} else {
		    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value + '/priority_items/' + priority_item.value), {
			name: priority_item.value,
			priority: priority.value
		    });
		    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value + '/priority_items/' + priority_item.value + '/tasks/' + name.value), {
			name: name.value,
			priority_item: priority_item.value,
			priority: priority.value,
			due_date: due_date.value.toString()
		    });

		}
	    }).catch((error) => {
		console.error(error);
	    });

	    alert("task added");

	} catch (error) {
	    alert(error);
	}
    };


    const handleSubmitPriority = (e) => {
	e.preventDefault();
	const { priority, color, order } = e.target.elements;
	try {
	    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value), {
		name: priority.value,
		color: color.value,
		order: order.value
	    });

	    alert("priority added");

	} catch(error) {
	    alert(error);
	}

    };

    const handleSubmitPriorityItem = (e) => {
	e.preventDefault();
	const { priority_item, priority } = e.target.elements;
	try {
	    set(ref(db, '/users/' + currentUser.uid + '/priorities/' + priority.value + '/priority_items/' + priority_item.value), {
		name: priority_item.value,
		priority: priority.value
	    });

	    alert("priority item added");

	} catch(error) {
	    alert(error);
	}

    };
    return (
	<>

	<div className="content">
	<div className="title">
	  <p><button onClick={() => navigate("/tasks")}><i className="heading2 bi bi-arrow-left" style={{paddingRight: "1em"}}></i></button></p>
          <p><span className='heading2'>Add a task or priority</span></p>
	</div>


	<div className="forms">

	  <div className="column"></div>
	  <div className="column">

	  <form onSubmit={handleSubmitTask}>
	    <p className="heading2" style={{fontWeight: "bold"}}>Task</p>
	    
	    <select name="priority">
	      { priorities.map((priority, index) =>
		  <option key={index}>{priority.name}</option>
	      )}
	    </select><br/>
	    
	    <input name="name" type="text" placeholder="taskname" /><br/>
	    
	    <select name="priority_item">
	      { priorityItems.map((pItem, index) =>
		  <option key={index}>{pItem}</option>
	      )}
	    </select><br/>
	    	    
	    <input name="due_date" type="date" /><br/>

	    <br/>
	    <div style={{marginTop: "4px", paddingLeft: "0px"}}>
            <button type="submit" className="btn btn-dark">Add task</button>
	    </div>

	  </form>

	  </div>

	  <div className="column">

	  <form onSubmit={handleSubmitPriority}>
	    <p className="heading2" style={{fontWeight: "bold"}}>Priority</p>
	  
	    <input name="priority" type="text" placeholder="priority" /><br/>
	    <input name="color" type="color" placeholder="color" /><br/>
	    <input name="order" type="number" placeholder="order" /><br/>

	    <br/><br/>
	    <div style={{marginTop: "4px", paddingLeft: "0px"}}>
	    <button type="submit" className="btn btn-dark">Add priority</button>
            </div>
	  </form>
	  
	  </div>
 	  <div className="column">

	  <form onSubmit={handleSubmitPriorityItem}>

	    <p className="heading2" style={{fontWeight: "bold"}}>Priority Item</p>

	    <input name="priority_item" type="text" placeolder="priority item" /><br/>
	    <select name="priority">
	      { priorities.map((priority, index) =>
		  <option key={index}>{priority.name}</option>
	      )}
	    </select><br/>
	    <br/><br/>
	    <div style={{marginTop: "4px", paddingLeft: "0px"}}>
	    <button type="submit" className="btn btn-dark">Add priority Item</button>
            </div>
	  </form>

	  </div>
	</div>
	</div>
	</>
    );
};

export default AddPage;
