import React, { useContext, useEffect } from "react";
import { useNavigate, Link} from "react-router-dom";
import { AuthContext } from "./Auth";

const Dashboard = () => {

    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);
    
    useEffect(() => {
    	document.title = "home";
	if (!currentUser) {
	    navigate("/");
    	}	

    });

    return (
	<>

	<div className="content">
	  <button className="index-button" onClick={() => navigate("/home")}>Home</button>
	  <button className="index-button" onClick={() => navigate("/tasks")}>Tasks</button>
	  <button className="index-button" onClick={() => navigate("/priorities")}>Priorities</button>
	</div>

	</>
    );
};

export default Dashboard;
