import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth";
import { logInWithEmailAndPassword } from "../firebase";
import "./Login.css";

const Login = () => {
    
    const navigate = useNavigate();

    const handleSubmit = (e) => {
	e.preventDefault();
	const { email, password } = e.target.elements;
	try {
	    logInWithEmailAndPassword(email.value, password.value);
	} catch (error) {
	    alert(error);
	}
    };

    const { currentUser } = useContext(AuthContext);
    useEffect(() => {
	document.title = "shandukani | login";
	if (currentUser) {
	    navigate("/dashboard");
	}
    });

    return (
	  <form onSubmit={handleSubmit}>

	    <h3>Log In</h3>

	    <div className="form-group">
	      <label>Email</label>
	      <input name="email" type="email" placeholder="Email" />
	    </div>
	
	    <div className="form-group">
	      <label>Password</label>
	      <input name="password" className="form-control" type="password" placeholder="Password" />
            </div>

	    <div>
	      <button type="submit" className="btn btn-primary btn-block">Submit</button>
	    </div>  

	  </form>
    );
};

export default Login;
