import React, { useContext, useEffect, onValue, exists } from "react";
import { useNavigate, Link} from "react-router-dom";
import { AuthContext } from "./Auth";
import { auth, db, logout } from "../firebase";
import { query, get, equalTo, ref, update, child, set, orderByChild, remove } from "firebase/database";

function DeleteTask(userId, task) {
    



    try {

	const dbRef = ref(db);
	remove(child(dbRef, '/users/' + userId + '/priorities/' + task.priority + '/priority_items/' + task.priority_item + '/tasks/' + task.name));


    } catch (error) {
	alert(error);
    }


	




};

function DeletePriorityItem(userId, priority_item) {

    try {
	const dbRef = ref(db);
	console.log(priority_item);
	remove(child(dbRef, '/users/' + userId + '/priorities/' + priority_item.priority + '/priority_items/' + priority_item.name));

    } catch (error) {
	alert(error);
    }

};

export { DeleteTask, DeletePriorityItem };
