import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link} from "react-router-dom";
import { AuthContext } from "./Auth";
import { auth, db, logout } from "../firebase";
import { onValue, ref, update } from "firebase/database";


const EditPage = () => {

    const { currentUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const [priorities, setPriorities] = useState([]);
    
    useEffect(() => {
    	document.title = "home | (edit)";
	if (!currentUser) {
	    navigate("/");
    	}
	try {
	   
	    const userId = currentUser.uid;

	    onValue(ref(db, '/users/' + userId + '/priorities/'), (snapshot) => {
		snapshot.forEach(snap => {
		    setPriorities(priorities => [...priorities, snap.val()]);
		});
	    }, {
		onlyOnce: true
	    });

	} catch(error) {
	    alert(error);
	}

    }, []);

    const editPriority = (e) => {
	e.preventDefault();
	const { priority, color, order } = e.target.elements;
	console.log(priority, color, order);


	const updates = {};
	updates['/users/' + currentUser.uid + '/priorities/' + priority.value + '/name'] = priority.value;
	updates['/users/' + currentUser.uid + '/priorities/' + priority.value + '/color'] = color.value;
	updates['/users/' + currentUser.uid + '/priorities/' + priority.value + '/order'] = order.value;

	try {
	    const dbRef = ref(db);
	    update(ref(db), updates);
	} catch(error) {
	    alert(error);
	}
    };

    return (
	<div>
	  <h1>Edit page</h1>

	  <form onSubmit={editPriority}>
	  
	    <select name="priority">
	      { priorities.map((priority, index) =>
		  <option key={index}>{priority.name}</option>
	      )}
	    </select>
	    <input name="color" type="color" />
	    <input name="order" type="number" />
	  
	    <button type="submit">Update</button>

	  </form>

	  <button onClick={() => navigate("/tasks")}>Done</button>
	  	  
	  <button onClick={() => logout()}>Sign out</button>

	</div>
    );

};


export default EditPage;
