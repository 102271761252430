import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./components/Auth";

const Home = () => {

    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);
    
    useEffect(() => {
    	document.title = "home";
	if (!currentUser) {
	    navigate("/");
    	}	

    });

    return (
	<>

	<div className="content">

	  <div>
	    <p className="heading3">Goals</p>
	    <p>daily</p>
	    <ul>
	      	<li>Read an interesting article each morning</li>
		<li>Journal</li>
		<li>Take pictures</li>
		<li>Read a chapter of a book</li>
		<li>Listen to a podcast</li>
		<li>Stretch/yoga/meditate</li>
		<li>run/workout</li>
		<li>manifestation boards</li>
		<li>read articles</li>
		<li>learn new concepts</li>
		<li>cook</li>
	    </ul>
	  </div>

	    <p className="heading3">Ongoing</p>
	    <ul>
		<li>shop sustainably</li>
		<li>piano</li>
		<li>explore github public contribution projects</li>
	    </ul>

	    <p className="heading3">saving money</p>
	    <ul>
		<li>meal prep</li>
		<li>go to the grocery store less</li>
	    </ul>
	    
	  
	</div>

	</>
    );
};

export default Home;
