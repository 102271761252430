import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./Auth";
import { db } from "../firebase";
import "./TasksPriorities.css";
import PriorityItemComponent from "./PriorityItemComponent";
import { ref, onValue } from "firebase/database";

function Priorities() {

    const navigate = useNavigate();
    
    const { currentUser } = useContext(AuthContext);
    const [priorities, setPriorities] = useState([]);

    useEffect(() => {
	document.title = "home | priorities";
    	if (!currentUser) {
	    navigate("/");
    	}
	try {

	    const userId = currentUser.uid;
	    onValue(ref(db, '/users/' + userId + '/priorities/'), (snapshot) => {
	    snapshot.forEach(snap => {
		setPriorities(priorities => [...priorities, snap.val()]);
	    });

	}, {
	    onlyOnce: true
	});


	} catch(error) {
	    alert(error);
	}
    }, [currentUser, navigate]);

    //let date = new Date().toDateString();

    return ( 
	<>
	<div className="content">

	  <div className="title-bar">
	    <div className="title">
	      <p><span className="heading1">Priorities</span></p>
	    </div>
	    <div className='flex-container'>
	    <div className="tools"> 
	      <button onClick={() => navigate("/addpage")
	      } className="btn btn-dark" style={{marginRight: "3px"}}><i className="bi bi-plus-circle"></i>  Add</button>
	      <button onClick={() => navigate("/editpage")} className="btn btn-dark"><i className="bi bi-pencil"></i>  Edit</button>
	    </div>
	    </div>
	  </div>

	    
	
	<div className='flex-container'>

	    { priorities.map((priority, index) =>
		<div className='priority' style={{backgroundColor: priority.color, order: priority.order}} key={index}>
		  <div className='priority-header'>
		    <div className='header-text'>{priority.name.toUpperCase()}</div>
		  </div>
		    <PriorityItemComponent value={priority.name}/>
		  </div>
	    )}

	  </div>

	  	  
	  
	</div>

	</>


    );



};

export default Priorities;
