import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "../../firebase";
import "./TopNavigation.css";
import Image from "../../img/shape_big_oranges.png";

function TopNavigation() {

    const navigate = useNavigate();
    
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
	<>
	<div className="top-bar">
	  
	  <div className="icon">
	    <button className="top-btn" onClick={() => handleClick()}><img src={Image} width="35px" height="35px" /></button>
	  </div>

	  <div className="navigation">
	    <button className="top-btn" onClick={() => logout()}><i className="bi bi-box-arrow-right"></i></button>
	  </div>

	</div>

	{ click ? 
	    <div className="popupmenu">
	    
	    <button onClick={() => {navigate("/dashboard"); handleClick();}}><div className="top-btn nav-links"><i className="bi bi-speedometer2"></i></div></button>
	    <button onClick={() => {navigate("/calendar"); handleClick();}}><div className="top-btn nav-links"><i className="bi bi-calendar3"></i></div></button>
	    <button onClick={() => {navigate("/home"); handleClick();}}><div className="top-btn nav-links">HOME</div></button>
	    <button onClick={() => {navigate("/priorities"); handleClick();}}><div className="top-btn nav-links">PRIORITIES</div></button>
            <button onClick={() => {navigate("/tasks"); handleClick();}}><div className="top-btn nav-links">TASKS</div></button>
	    </div> 

	    : null }
      
    </>
    );

};

export default TopNavigation;
