import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";

import {
    getDatabase,
    set,
    ref,
} from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyCpJUvZujMdC5a6Rh4_sue2Cly7qLmMfX0",
  authDomain: "home-shandukani-0.firebaseapp.com",
  databaseURL: "https://home-shandukani-0-default-rtdb.firebaseio.com",
  projectId: "home-shandukani-0",
  storageBucket: "home-shandukani-0.appspot.com",
  messagingSenderId: "575027002386",
  appId: "1:575027002386:web:f40333298436a7675beccb",
  measurementId: "G-QX5RMFFS0T"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const db = getDatabase();
    await set(ref(db, 'users/' + user.uid), {
	username: name,
	email: email,
	uid: user.uid,
	authProvider: "local"
    });
   } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
