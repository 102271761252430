import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { AuthProvider } from "./components/Auth";
import Login from "./components/Login";

import TopNavigation from "./components/topnav/TopNavigation"
import Dashboard from "./components/Dashboard";

import Home from "./home";
import Tasks from "./components/Tasks";
import Priorities from "./components/Priorities";
import Calendar from "./components/Calendar";

import AddPage from "./components/AddPage";
import EditPage from "./components/EditPage";


function App() {
    return (

	<AuthProvider>


	<Router>
	  <TopNavigation />
	  <Routes>
      	    <Route exact path="/" element={<Login />} />
	    <Route exact path="/dashboard" element={<Dashboard />} />
	    <Route exact path="/tasks" element={<Tasks />} />
	    <Route exact path="/addpage" element={<AddPage />} />
	    <Route exact path="/editpage" element={<EditPage />} />
	    <Route exact path="/priorities" element={<Priorities />} />
	    <Route exact path="/home" element={<Home />} />
	    <Route exact path="/calendar" element={<Calendar />} />
          </Routes>
  	</Router>


	</AuthProvider>
    )

}

export default App;
