import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./Auth";
import { useNavigate } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";
import { DeletePriorityItem } from "./Delete";

function PriorityItemComponent(props) {

    const { value } = props;

    const this_priority = value;

    const navigate = useNavigate();
    function refreshPage() {
	window.location.reload(true);
    };

    const { currentUser } = useContext(AuthContext);
    const [curPriorityItems, setCurPriorityItems] = useState([]);
    
    useEffect(() => {

	if (!currentUser) {
	    navigate("/");
	}

	try {
	const userId = currentUser.uid;
    
	onValue(ref(db, '/users/' + userId + '/priorities/' + this_priority + '/priority_items'), (snapshot) => {
	    snapshot.forEach(snap => {
		setCurPriorityItems(curPriorityItems => [...curPriorityItems, snap.val()]);
	    });

	}, {
	    onlyOnce: true
	});

	} catch(error) {
	    navigate("/");
	}

    
    }, [currentUser, navigate, this_priority]);

    const [show,setShow] = useState(false);
    const [clickedPriorityItem, setClickedPriorityItem] = useState({});


 

    return (
	<>
	  {curPriorityItems.map((priorityItem,index) => 
		  <div className="item" key={index}>
			<button className="item-button" onClick={() => { setShow(!show); setClickedPriorityItem(priorityItem);}}>
	              {priorityItem.name}
	      	    </button>      
	          </div>
	  )}

	  { show ? <div className="item-tools">
		      <button className="btn" onClick={() => { setShow(!show); }}><i className="bi bi-x" style={{fontSize: "0.9rem"}}></i></button>
	              <button className="btn btn-success btn-sm" onClick={() => { setShow(!show); DeletePriorityItem(currentUser.uid, clickedPriorityItem); refreshPage(); }}><i className="bi bi-check2-circle" style={{fontSize: "1.5rem"}}></i></button>
	            </div> : null }
	      
	</>
    );
};


export default PriorityItemComponent;

    
