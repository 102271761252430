import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link} from "react-router-dom";
import { AuthContext } from "./Auth";
import { ref, onValue } from "firebase/database";
import { db } from "../firebase";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const Calendar = () => {

    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);
    const [curTasks, setCurTasks] = useState([]);

    useEffect(() => {
        document.title = "calendar";
	if (!currentUser) {
	    navigate("/");
    	}

	try {
	const userId = currentUser.uid;
    
	onValue(ref(db, '/users/' + userId + '/priorities/'), (snapshot0) => {
	    snapshot0.forEach(snap0 => {
		let priority = snap0.val().name;
		onValue(ref(db, '/users/' + userId + '/priorities/' + priority + '/priority_items/'), (snapshot1) => {
		    snapshot1.forEach(snap1 => {
			let priorityItem = snap1.val();
			try {
			    Object.entries(priorityItem['tasks']).map(([key, value]) => {
				console.log(value);
				setCurTasks(curTasks => [...curTasks, {title: value.name, date: value.due_date, backgroundColor: snap0.val().color}]);
			    });
			} catch(error) {
			}
		    });
		}, {
		    onlyOnce: true
		});
	    });
	}, {
	    onlyOnce: true
	});


	} catch(error) {
	    navigate("/");
	}



    }, [currentUser, navigate]);

    return (
    <div style={{height:"50%"}}>
    <FullCalendar
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
	headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
	events={curTasks}
	eventTextColor="black"
      />
	</div>
    )



};

export default Calendar;

